<template>
    <section class="more-info">
        <b-row>
            <b-col md="1">
                <img
                    class="moto"
                    src="@/assets/img/ic-moto.png"
                    alt="zubut driver"
                    width="64"
                    height="64"
                />
            </b-col>
            <b-col md="6">
                <p class="z-text-blue z-text-bigger text-md-left">
                    ¿Necesitas más información?
                </p>
                <p class="z-text-bigger text-md-left">
                    Envíanos un mensaje y pronto estaremos en contacto contigo.
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col offset-md="1" md="3">
                <router-link :to="{ name: 'contact' }" @click.native="handleClick">
                    <z-button uppercase size="lg" bold>
                        Contáctanos
                    </z-button>
                </router-link>
            </b-col>
        </b-row>
    </section>
</template>

<script>
import ZButton from "@/components/ZButton";
import trackActionWithAnalytics from "@/utils/track-Action-With-Analytics.js";

export default {
    name: "RequestInfo",
    components: {
        ZButton
    },
    methods: {
        handleClick() {
            trackActionWithAnalytics.facebook("Lead", "ir a contacto");
            trackActionWithAnalytics.google("link", "ir a contacto");
        }
    }
};
</script>

<style lang="scss" scoped>
.more-info {
    .moto {
        transform: rotate(180deg);
        margin-bottom: 1em;
        margin-top: 14px;
    }
}
</style>
