<template>
    <b-container fluid>
        <b-row class="text-left z-background-blue text-light">
            <b-col>
                <b-container>
                    <b-row class="py-5">
                        <b-col>
                            <h1 class="z-text-bold z-text-bigger">
                                Cómo funciona
                            </h1>
                            <p class="m-0">
                                Conoce cada detalle de la plataforma
                            </p>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <b-row class="z-background-light-grey features-index">
            <b-col>
                <b-container>
                    <b-row class="text-left py-4">
                        <b-col sm="6" md="4">
                            <b-nav vertical>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        v-scroll-to="'#cotizar-envio'"
                                        to="/como-funciona#cotizar-envio"
                                    >
                                        Cotizar un envío
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        v-scroll-to="'#personalizar-envio'"
                                        to="/como-funciona#personalizar-envio"
                                    >
                                        Personalizar un envío
                                    </router-link>
                                </li>
                            </b-nav>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-nav vertical>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        v-scroll-to="'#programar-envio'"
                                        to="/como-funciona#programar-envio"
                                    >
                                        Programar un envío
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        v-scroll-to="'#rastrear-pedido'"
                                        to="/como-funciona#rastrear-pedido"
                                    >
                                        Rastrear tu pedido
                                    </router-link>
                                </li>
                            </b-nav>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-nav vertical>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        v-scroll-to="'#edit-service'"
                                        to="/como-funciona#edit-service"
                                    >
                                        Editar un envío
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        v-scroll-to="'#api-integration'"
                                        to="/como-funciona#api-integration"
                                    >
                                        Integración de API
                                    </router-link>
                                </li>
                            </b-nav>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-nav vertical>
                                <li class="nav-item">
                                    <router-link
                                        class="nav-link"
                                        v-scroll-to="'#rent-per-hour'"
                                        to="/como-funciona#rent-per-hour"
                                    >
                                        Renta por horas<span class="ml-1 new-feature">¡Nuevo!</span>
                                    </router-link>
                                </li>
                            </b-nav>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <hr style="margin: 0px;" />
        <b-container>
            <a class="anchor" id="cotizar-envio" href="#"></a>
            <b-row class="text-left py-5">
                <b-col class="order-md-12 d-flex">
                    <b-row class="align-self-center">
                        <b-col md="1" class="d-none d-md-block">
                            <img src="@/assets/img/ic-destination.png" alt="destino" />
                        </b-col>
                        <b-col md="7">
                            <h2 class="z-text-bigger z-text-bold">
                                Cotizar un envío
                            </h2>
                            <p class="z-text-medium">
                                Ingresa el nombre y teléfono de tu destino, añade cruces y/o
                                referencias para facilitar la ubicación.
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" md="6" class="order-md-1">
                    <img class="img-fluid" src="@/assets/img/estimate-cost.png" alt="cotizar" />
                </b-col>
            </b-row>
        </b-container>
        <b-container class="custom-service">
            <a class="anchor" id="personalizar-envio" href="#"></a>
            <b-row class="text-left py-5 mb-5">
                <b-col cols="12">
                    <b-row>
                        <b-col md="7" class="d-flex order-md-1">
                            <div class="mr-5 d-none d-md-block">
                                <img src="@/assets/img/ic-destination.png" alt="destino" />
                            </div>
                            <div>
                                <h2 class="z-text-bigger z-text-bold">
                                    Personalizar un envío
                                </h2>
                                <p class="z-text-medium">
                                    Despliega el menú de opciones para personalizar tus envíos, con
                                    Zubut puedes:
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    offset-md="1"
                    class="order-md-2 d-flex text-md-right feature"
                >
                    <div class="pr-2 pr-md-0 pl-md-2 order-md-12">
                        <img src="@/assets/icons/ic-check.svg" alt="marca" />
                    </div>
                    <div class="order-md-1">
                        <h3 class="z-text-bigger z-text-medium z-text-color-light-blue">
                            Multidestinos
                        </h3>
                        <p class="z-text-medium">
                            Agrega hasta 5 destinos a tu viaje.
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" md="5" offset-md="7" class="order-md-4 d-flex feature">
                    <div class="pr-2">
                        <img src="@/assets/icons/ic-check.svg" alt="marca" />
                    </div>
                    <div>
                        <h3 class="z-text-bigger z-text-medium z-text-color-light-blue">
                            Firma electrónica
                        </h3>
                        <p class="z-text-medium">
                            Indícanos si necesitas firma electrónica.
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" md="5" offset-md="7" class="order-md-5 d-flex feature">
                    <div class="pr-2">
                        <img src="@/assets/icons/ic-check.svg" alt="marca" />
                    </div>
                    <div>
                        <h3 class="z-text-bigger z-text-medium z-text-color-light-blue">
                            Evidencia
                        </h3>
                        <p class="z-text-medium order-md-6">
                            Envianos evidencia fotográfica.
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" md="5" offset-md="7" class="order-md-7 d-flex feature">
                    <div class="pr-2">
                        <img src="@/assets/icons/ic-check.svg" alt="marca" />
                    </div>
                    <div>
                        <h3 class="z-text-bigger z-text-medium z-text-color-light-blue">
                            Depósitos
                        </h3>
                        <p class="z-text-medium">
                            Puedes solicitar que tu mensajero haga un depósito antes de finalizar tu
                            servicio.
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" class="order-md-3 infographic">
                    <img class="img-fluid" src="@/assets/img/customize.png" alt="cotizar" />
                </b-col>
            </b-row>
        </b-container>
        <a class="anchor" id="programar-envio" href="#"></a>
        <b-row class="z-background-light-grey schedule-service py-5 mt-5">
            <b-col>
                <b-container>
                    <b-row class="text-left py-5">
                        <b-col class="d-flex">
                            <b-row class="align-self-center">
                                <b-col md="1" class="d-none d-md-block">
                                    <img src="@/assets/img/ic-destination.png" alt="destino" />
                                </b-col>
                                <b-col md="7">
                                    <h2 class="z-text-bigger z-text-bold">
                                        Programar un envío
                                    </h2>
                                    <p class="z-text-medium">
                                        Agenda tu servicio, solo indícanos la hora en que lo
                                        necesitas y listo.
                                    </p>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col md="6">
                            <img class="img-fluid" src="@/assets/img/schedule.png" alt="cotizar" />
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <b-row class="py-5 my-5">
            <a class="anchor" id="rastrear-pedido" href="#"></a>
            <b-col>
                <b-container>
                    <b-row class="text-left pt-5">
                        <b-col md="7" class="d-flex order-md-12">
                            <div class="mr-5 d-none d-md-block">
                                <img src="@/assets/img/ic-destination.png" alt="destino" />
                            </div>
                            <div>
                                <h2 class="z-text-bigger z-text-bold">
                                    Rastrea tu pedido
                                </h2>
                                <p class="z-text-medium">
                                    Recibe notificaciones con el estatus de tu servicio,
                                    actualizaciones del mensajero etc.
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="text-left follow-your-request pb-5">
                        <b-col cols="12" md="5" offset-md="7" class="mb-2">
                            <img src="@/assets/icons/ic-check.svg" alt="check" />
                            <h3 class="z-text-big z-text-medium">
                                Seguimiento en tiempo real
                            </h3>
                        </b-col>
                        <b-col cols="12" md="5" offset-md="7" class="mb-2">
                            <img src="@/assets/icons/ic-check.svg" alt="check" />
                            <h3 class="z-text-big z-text-medium">
                                Revisa tus destinos
                            </h3>
                        </b-col>
                        <b-col cols="12" md="5" offset-md="7">
                            <img src="@/assets/icons/ic-check.svg" alt="check" />
                            <h3 class="z-text-big z-text-medium">
                                Revisa tarifa y método de pago
                            </h3>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <img
                                class="img-fluid"
                                src="@/assets/img/follow-service.png"
                                alt="cotizar"
                            />
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <b-container>
            <a class="anchor" id="edit-service" href="#"></a>
            <b-row class="text-left py-5 text-md-center">
                <b-col cols="12" class="order-md-12">
                    <h2 class="z-text-bigger z-text-bold">
                        Editar un viaje
                    </h2>
                    <p class="z-text-medium">
                        Editar / Agregar destinos a tu viaje en curso
                    </p>
                </b-col>
                <b-col cols="12" class="order-md-12">
                    <img class="img-fluid" src="@/assets/img/edit-service.png" alt="cotizar" />
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <a class="anchor" id="rent-per-hour" href="#"></a>
            <b-row class="text-left py-5 text-md-center rent-per-hour">
                <b-col cols="12" md="6" lg="4" class="order-md-12 text-md-left">
                    <span class="new-feature">¡Nuevo!</span>
                    <h2 class="z-text-bigger z-text-bold mt-2">
                        Reservaciones por hora
                    </h2>
                    <p class="z-text-medium mt-4">
                        Reservando por hora, puedes solicitar mensajeros Zubut exclusivos para tu
                        restaurante/negocio desde nuestra plataforma.<br /><br />
                        Reserva más de un día por bloques de horas según tu negocio necesite. Un
                        bloque equivale a 4 horas, el cual puedes crecerlo sin límite dentro del
                        horario de servicio.
                    </p>
                    <z-button
                        uppercase
                        size="lg"
                        bold
                        class="mb-5 mt-lg-3"
                        @click="$router.push({ name: 'registrar-empresa' })"
                    >
                        Registra tu empresa
                    </z-button>
                </b-col>
                <b-col cols="12" md="6" lg="8" class="screen-shot-section order-md-12 p-0">
                    <img
                        class="img-fluid"
                        src="@/assets/img/renta-por-horas.png"
                        alt="Reservaciones por hora"
                    />
                </b-col>
            </b-row>
            <b-row class="text-sm-left mb-lg-5">
                <b-col cols="12" sm="4">
                    <h3 class="z-text-bigger z-text-bold">
                        Descuentos
                    </h3>
                    <p>
                        Costo por hora disminuye desde $135 mxn hasta $86 mxn al agregar más horas.
                    </p>
                </b-col>
                <b-col cols="12" sm="4">
                    <h3 class="z-text-bigger z-text-bold mt-4 mt-sm-0">
                        Facilidad de pago
                    </h3>
                    <p>
                        Paga por medio de Zubut Wallet, la cual la puedes recargar por medio de
                        transferencia bancaria o pago en Oxxo.
                    </p>
                </b-col>
                <b-col cols="12" sm="4">
                    <h3 class="z-text-bigger z-text-bold mt-4 mt-sm-0">
                        Puntualidad
                    </h3>
                    <p class="mb-5">
                        Nuestros mensajeros se presentarán 15 min. antes en el punto de origen.
                    </p>
                </b-col>
            </b-row>
        </b-container>
        <b-row class="z-background-light-grey">
            <a class="anchor" id="api-integration" href="#"></a>
            <b-col>
                <b-container class="pt-5 text-center">
                    <b-row class="pt-5 pb-4">
                        <b-col>
                            <h4 class="z-text-blue z-text-small">
                                Desarrolladores
                            </h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <h2 class="z-text-bigger z-text-bold">
                                Integración de APIs con Zubut
                            </h2>
                            <p class="z-text-medium">
                                Fácil y sencilla, además podemos adecuarnos a tus necesidades.
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="mx-auto py-5">
                        <b-col>
                            <a href="https://api.zubut.com/doc/" target="_blank">
                                <z-button style="width: auto;" variant="secondary">
                                    Ver documentación de API
                                </z-button>
                            </a>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <img
                                class="img-fluid api-integration"
                                src="@/assets/img/api-integration.png"
                                alt="cotizar"
                            />
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <b-container class="mt-md-5">
            <div class="mt-md-5">
                <request-info />
                <press-bar :show-pin="true" class="mb-5" />
            </div>
        </b-container>
    </b-container>
</template>

<script>
import PressBar from "@/components/PressBar";
import RequestInfo from "@/components/RequestInfo";
import ZButton from "@/components/ZButton";

export default {
    name: "HowItWorks",
    metaInfo: {
        title: "Cómo funciona",
        meta: [
            {
                name: "description",
                content: `Conoce cada detalle de la plataforma.`
            }
        ]
    },
    components: {
        PressBar,
        RequestInfo,
        ZButton
    }
};
</script>

<style lang="scss" scoped>
section {
    padding: 1em;
}

.features-index {
    a {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.follow-your-request {
    div {
        display: flex;

        img {
            margin-right: 1em;
        }
    }
}

.rent-per-hour .screen-shot-section {
    margin-left: 15px;
}

.new-feature {
    border-radius: 2px;
    font-size: 10px;
    font-weight: 800;
    padding: 0.2em;
    color: #18b28d;
    background-color: #d7f7ef;
    text-transform: uppercase;
}

@media only screen and (min-width: 768px) {
    .custom-service {
        .feature {
            &:nth-child(2) {
                margin-top: 100px;
            }

            &:nth-child(3) {
                margin-top: -250px;
            }

            &:nth-child(4) {
                margin-top: -140px;
            }

            &:nth-child(5) {
                margin-top: -40px;
            }
        }

        .infographic {
            margin-top: -240px;
        }
    }

    .schedule-service {
        img {
            margin-top: -120px;
            margin-bottom: -120px;
        }
    }

    .rent-per-hour .screen-shot-section {
        margin-left: initial;
    }
}

@media only screen and (min-width: 992px) {
    .api-integration {
        max-width: 680px;
    }

    .rent-per-hour button {
        max-width: 250px;
    }
}
</style>
